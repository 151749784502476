
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


@font-face {
  font-family: "Biotif-Bold";
  src: url("./Assets/Fonts/Biotif-Bold.ttf");
}

@font-face {
  font-family: "Biotif-Book";
  src: url("./Assets/Fonts/Biotif-Book.ttf");
}

@font-face {
  font-family: "Biotif-Light";
  src: url("./Assets/Fonts/Biotif-Light.ttf");
}

@font-face {
  font-family: "Righteous";
  src: url("./Assets/Fonts/Righteous-Regular.ttf");
}

@font-face {
  font-family: "Eina";
  src: url("./Assets/Fonts/Eina01-Regular.ttf");
}

@font-face {
  font-family: "EinaBold";
  src: url("./Assets/Fonts/Eina01-Bold.ttf");
}

@font-face {
  font-family: "EinaRegular";
  src: url("./Assets/Fonts/Eina01-Regular.ttf");
}

@font-face {
  font-family: "EinaSemiBold";
  src: url("./Assets/Fonts/Eina01-SemiBold.ttf");
}



@font-face {
  font-family: "MarkProHeavy";
  src: url("./Assets/Fonts/MARKPROHEAVY.OTF");
}

@font-face {
  font-family: "MarkProMedium";
  src: url("./Assets/Fonts/MARKPROMEDIUM.OTF");
}

@font-face {
  font-family: "MarkProBold";
  src: url("./Assets/Fonts/MARKPROBOLD.OTF");
}

@font-face {
  font-family: "MarkProLight";
  src: url("./Assets/Fonts/MARKPROLIGHT.OTF");
}

@font-face {
  font-family: "SGBold";
  src: url("./Assets/Fonts/SpaceGrotesk-Bold.ttf");
}

@font-face {
  font-family: "SGLight";
  src: url("./Assets/Fonts/SpaceGrotesk-Light.ttf");
}

@font-face {
  font-family: "PPMedium";
  src: url("./Assets/Fonts/SpaceGrotesk-Medium.ttf");
}

@font-face {
  font-family: "SGRegular";
  src: url("./Assets/Fonts/SpaceGrotesk-Regular.ttf");
}

@font-face {
  font-family: "PPBook";
  src: url("./Assets/Fonts/SpaceGrotesk-SemiBold.ttf");
}


@font-face {
  font-family: "PoppinsBold";
  src: url("./Assets/Fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: "PoppinsBlack";
  src: url("./Assets/Fonts/Poppins-Black.ttf");
}



@font-face {
  font-family: "PPBook";
  src: url("./Assets/Fonts/PPNeueMontreal-Book.otf");
}

@font-face {
  font-family: "PPSemiBold";
  src: url("./Assets/Fonts/PPNeueMontreal-SemiBolditalic.otf");
}


@font-face {
  font-family: "PPItalic";
  src: url("./Assets/Fonts/PPNeueMontreal-Italic.otf");
}

@font-face {
  font-family: "PPMedium";
  src: url("./Assets/Fonts/PPNeueMontreal-Medium.otf");
}


@font-face {
  font-family: "PPSemiBoldItalic";
  src: url("./Assets/Fonts/PPNeueMontreal-SemiBolditalic.otf");
}


@font-face {
  font-family: "PPBold";
  src: url("./Assets/Fonts/PPNeueMontreal-Bold.otf");
}


@font-face {
  font-family: "PPItalic";
  src: url("./Assets/Fonts/PPNeueMontreal-Italic.otf");
}



@font-face {
  font-family: "SGBold";
  src: url("./Assets/Fonts/Poppins-ExtraBold.ttf");
}

@font-face {
  font-family: "SGRegular";
  src: url("./Assets/Fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: "PPMedium";
  src: url("./Assets/Fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("./Assets/Fonts/Inter-Bold-slnt=0.ttf");
}

@font-face {
  font-family: "Inter-Black";
  src: url("./Assets/Fonts/Inter-Black-slnt=0.ttf");
}

@font-face {
  font-family: "Inter-Light";
  src: url("./Assets/Fonts/Inter-Light-slnt=0.ttf");
}

@font-face {
  font-family: "Inter-Medium";
  src: url("./Assets/Fonts/Inter-Medium-slnt=0.ttf");
}

@font-face {
  font-family: "Inter-Regular";
  src: url("./Assets/Fonts/Inter-Regular-slnt=0.ttf");
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("./Assets/Fonts/Inter-SemiBold-slnt=0.ttf");
}

@font-face {
  font-family: "GellixBold";
  src: url("./Assets/Fonts/GellixBold.otf");
}

@font-face {
  font-family: "LarsLight";
  src: url("./Assets/Fonts/LarsseitLight.ttf");
}

@font-face {
  font-family: "Yaro";
  src: url("./Assets/Fonts/YaroCut-Black.woff");
}

@font-face {
  font-family: "Sofia Pro";
  src: url("./Assets/Fonts/Sofia\ Pro\ Medium\ Az.otf");
}

@font-face {
  font-family: "Inter-Medium";
  src: url("./Assets/Fonts/static/PlusJakartaSans-Medium.ttf");
}

@font-face {
  font-family: "Inter-SemiBold";
  src: url("./Assets/Fonts/static/PlusJakartaSans-SemiBold.ttf");
}

@font-face {
  font-family: "PlusJSRegular";
  src: url("./Assets/Fonts/static/PlusJakartaSans-Regular.ttf");
}

@font-face {
  font-family: "PlusJSLight";
  src: url("./Assets/Fonts/static/PlusJakartaSans-Light.ttf");
}

@font-face {
  font-family: "Inter-Bold";
  src: url("./Assets/Fonts/static/PlusJakartaSans-Bold.ttf");
}

@font-face {
  font-family: "PlusJSExtraBold";
  src: url("./Assets/Fonts/static/PlusJakartaSans-ExtraBold.ttf");
}

@font-face {
  font-family: "Grifter";
  src: url("./Assets/Fonts/Grifter\ Sans\ Serif\ Font/grifterbold.otf");
}

@font-face {
  font-family: "CSBold";
  src: url("./Assets/Fonts/CerebriSansPro-Bold.ttf");
}

@font-face {
  font-family: "CSExtraBold";
  src: url("./Assets/Fonts/CerebriSansPro-ExtraBold.ttf");
}


@font-face {
  font-family: "CSExtraLight";
  src: url("./Assets/Fonts/CerebriSansPro-ExtraLight.ttf");
}

@font-face {
  font-family: "CSHeavy";
  src: url("./Assets/Fonts/CerebriSansPro-Heavy.ttf");
}

@font-face {
  font-family: "CSLight";
  src: url("./Assets/Fonts/CerebriSansPro-Light.ttf");
}


@font-face {
  font-family: "CSMedium";
  src: url("./Assets/Fonts/CerebriSansPro-Medium.ttf");
}

@font-face {
  font-family: "CSRegular";
  src: url("./Assets/Fonts/CerebriSansPro-Regular.ttf");
}


@font-face {
  font-family: "CSSemiBold";
  src: url("./Assets/Fonts/CerebriSansPro-SemiBold.ttf");
}

@font-face {
  font-family: "MABlack";
  src: url("./Assets/Fonts/font/MierA-Black.ttf");
}
@font-face {
  font-family: "MABold";
  src: url("./Assets/Fonts/font/MierA-Bold.ttf");
}

@font-face {
  font-family: "MARegular";
  src: url("./Assets/Fonts/font/MierA-Regular.ttf");
}


@font-face {
  font-family: "MAExtraBold";
  src: url("./Assets/Fonts/font/MierA-ExtraBold.ttf");
}
@font-face {
  font-family: "MAExtraBoldItalic";
  src: url("./Assets/Fonts/font/MierA-ExtraBoldItalic.ttf");
}


@font-face {
  font-family: "MABoldItalic";
  src: url("./Assets/Fonts/font/MierA-BoldItalic.ttf");
}

@font-face {
  font-family: "MABook";
  src: url("./Assets/Fonts/font/MierA-Book.ttf");
}

@font-face {
  font-family: "MADemiBold";
  src: url("./Assets/Fonts/font/MierA-DemiBold.ttf");
}

@font-face {
  font-family: "MAHeavy";
  src: url("./Assets/Fonts/font/MierA-Heavy.ttf");
}

@font-face {
  font-family: "SSBold";
  src: url("./Assets/Fonts/SuisseIntl-Bold.ttf");
}

@font-face {
  font-family: "SSSemiBold";
  src: url("./Assets/Fonts/SuisseIntl-SemiBold.ttf");
}

@font-face {
  font-family: "SSMedium";
  src: url("./Assets/Fonts/SuisseIntl-Medium.ttf");
}

@font-face {
  font-family: "SSRegular";
  src: url("./Assets/Fonts/SuisseIntl-Regular.ttf");
}

@font-face {
  font-family: "SSMono";
  src: url("./Assets/Fonts/Suisse-Intl-Mono.ttf");
}








*:focus {
  outline: none !important;
}

input {
  outline: none !important;
}



input:focus,
input.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

html,
body,
#root,
.App {
  height: 100%;
  padding: 0px;
  margin: 0px;
  background-color: #E8E8E8	 !important;
  opacity: 1;


}




@media (max-width: 900px) {
  ::-webkit-scrollbar {
    display: none !important;
    z-index: 12000;
  }
}

.scrollmenu::-webkit-scrollbar {
  background-color: #eee;
  width: 10px;
  height: 7px;
  z-index: 12000;

}

.scrollmenu::-webkit-scrollbar-thumb {
  width: 10px;
  height: 7px;
  color: blue;
  background-color: #777;
  z-index: 12000;

}

.scrollmenu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eee;
  z-index: 12000;

}

::-webkit-scrollbar {
  background-color: #eee;
  width: 10px;
  height: 7px;
  z-index: 12000;

}

::-webkit-scrollbar-thumb {
  width: 10px;
  height: 7px;
  color: blue;
  background-color: #777;
  z-index: 12000;

}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eee;
  z-index: 12000;

}

.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  z-index: 12000;

}

.scrollmenu a:hover {
  background-color: #777;
  z-index: 12000;

}

.PhoneInputInput {
  border: none;
}

.visible-scrollbar,
.invisible-scrollbar,
.mostly-customized-scrollbar {
  display: block;
  width: 10em;

  height: 2em;
}

/* Demonstrate a "mostly customized" scrollbar
 * (won't be visible otherwise if width/height is specified) */
.mostly-customized-scrollbar::-webkit-scrollbar {
  width: 1px;
  height: 8px;
  background-color: #eee;
  /* or add it to the track */
}

/* Add a thumb */
.mostly-customized-scrollbar::-webkit-scrollbar-thumb {
  background: #777;
}

.scrollmenu {
  margin: 4px, 4px;
  padding: 4px;
  background-color: transparent;
  width: 300px;
  overflow-x: auto;

  white-space: nowrap;
}

*:focus {
  outline: none !important;
}


/* body {
  cursor: url('https://firebasestorage.googleapis.com/v0/b/tingen-b510e.appspot.com/o/samplevoice%2Fdefault%402x%20(2).png?alt=media&token=839c666c-1d30-4b0a-9545-40e5f58da512'),auto;
}

body {
  cursor: url('https://firebasestorage.googleapis.com/v0/b/tingen-b510e.appspot.com/o/samplevoice%2Fdefault%402x%20(2).png?alt=media&token=839c666c-1d30-4b0a-9545-40e5f58da512'), pointer;

} */