.backy {
  background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"),
    linear-gradient(180deg, #fbe8de, #fbe8de);
}

.backy9 {
  background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"),
    linear-gradient(180deg, #001529, #001529);
}

.backy3 {
  background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"),
    linear-gradient(180deg, #e1eaf4, #e1eaf4);
}

.buttonSpecial {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: white;
  box-shadow: 6px 6px 0 0 rgb(0 0 0 / 20%);
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-family: Grifter, sans-serif;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.5px;
  text-decoration: none;
}

.menuButton {
  font-family: "Inter-Medium";
  font-size: 15px;
  margin-top: -44px;
  border-radius: 5px;
  display: inline-block;
  margin-left: 27px;
  margin-right: 65px;
}

.menuButtonMobile {
  font-family: "Inter-Medium";
  font-size: 15px;
  border-radius: 5px;
  display: inline-block;
  top: 16px;
  position: absolute;
  right: 3%;
}
.menuButtonText {
  margin-top: 6px;
  text-align: "center";
  font-family: "PlusJSExtraBold";
  text-align: center;
  font-size: 15px;
}

.menuButtonDark {
  border: 1.3px solid white;
  color: white !important;
}
.menuButtonLight {
  border: 1.3px solid white;
  padding: 0px 12px;
  color: white !important;
  height: 36px;
}

.fade-in-out {
  opacity: 0;
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  transition: opacity 0.5s, visibility 0.5s 0s;
  z-index: 0 !important;
}
.visible {
  opacity: 1;
  visibility: visible;
  pointer-events: all !important;
  z-index: 9000 !important;
  display: block !important;
}

@keyframes scrollText {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.scrolling-text {
  display: inline-block;
  white-space: nowrap;
  animation: scrollText 20s linear infinite;
  width: 100vw;
}

@media only screen and (min-width: 600px) {
  .ais-SearchBox-input {
    height: 45px;
    width: 400px !important;
    margin-left: -20px !important;
    left: -40px;
    border-radius: 90px;
    display: "inline-block";
    color: #30312c;
    margin-top: -5px;
    background-color: white;
    font-family: "Inter-SemiBold";
    padding-left: 47px;
    padding-right: 10px;
    font-size: 13px;
    border: 2px solid rgb(234, 236, 240);
  }
}

@media only screen and (max-width: 600px) {
  .ais-SearchBox-input {
    height: 45px;
    width: 90vw !important;
    margin-left: 0px !important;
    left: 10px !important;
    border-radius: 100px;
    display: "inline-block";
    color: #30312c;
    margin-top: -5px;
    background-color: white;
    font-family: "Inter-SemiBold";
    padding-left: 47px;
    border: none;
    padding-right: 10px;
    font-size: 13px;
    border: 2px solid rgb(234, 236, 240);
  }
}
.ais-Hits {
  z-index: 80000 !important;
}
.ais-SearchBox-reset {
  display: none;
}

.ais-SearchBox-submit {
  display: none;
}

.custom-switch-instance .react-switch-handle {
  width: 18px !important;
  height: 18px !important;
  margin-top: 0px;
  margin-left: 0px;
  margin-right: 9px;
}

#discord-button {
  .icon {
    width: 25px;
    height: 25px;
    margin-right: 15px;

    svg {
      fill: white;
    }
  }
}

#discord-button-mobile {
  .icon {
    width: 25px;
    height: 25px;
    margin-right: 15px;

    svg {
      fill: white;
    }
  }
}

.search-bar::placeholder {
  color: #6686b8;
}

.hover-item:hover {
  background-color: #474743;
}

@keyframes popUp {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.popUpAnimation {
  animation: popUp 0.1s ease-in-out;
}

#discord-button {
  .icon {
    width: 25px;
    height: 25px;
    margin-right: 15px;

    svg {
      fill: white;
    }
  }

  a {
    color: white;
    font-weight: bold;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #7289da;
    text-decoration: none;

    &:hover {
      background-color: #6a7fc9;
    }
  }
}

#discord-button-mobile {
  .icon {
    width: 25px;
    height: 25px;
    margin-right: 15px;

    svg {
      fill: white;
    }
  }

  a {
    color: white;
    font-weight: bold;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    padding: 10px 15px;
    background-color: #7289da;
    text-decoration: none;

    &:hover {
      background-color: #6a7fc9;
    }
  }
}
