.backy {
  background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"),
    linear-gradient(180deg, #fbe8de, #fbe8de);
}

.backy2 {
  background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"),
    linear-gradient(180deg, #fff, #fff);
}

.backy3 {
  background-image: url("https://assets.website-files.com/5837424ae11409586f837994/61195e21f792d7065d2f56ad_noise.png"),
    linear-gradient(180deg, #e1eaf4, #e1eaf4);
}


input[type='range'] {
  accent-color: blue !important;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 0px;
  background: #d3d3d3;
  outline: none;
  -webkit-transition: .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: white;
  border: 1px solid blue;
  cursor: pointer;
  border-radius: 50%;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  border: 1px solid blue;
  cursor: pointer;
}

.slider::-webkit-slider-runnable-track {
  height: 15px;
  background: blue;
}

.slider::-moz-range-track {
  height: 15px;
  background: blue;
}



.buttonSpecial9 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 24px 30px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
  background-color: #09382f;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  font-family: "PlusJSMedium";
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.5px;
  text-decoration: none;
}

.onboarding::placeholder {
  font-size: 16px !important;
  font-style: oblique 30deg;
}

.match:hover {
  background-color: #f0f0f0;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.Opendates {
  display: inline-block;
}

.Opendates span {
  display: inline-block;
  animation: letter-animation 0.5s ease-in-out forwards;
  opacity: 0;
}

.Opendates span:nth-child(1) {
  animation-delay: 0.1s;
}

.Opendates span:nth-child(2) {
  animation-delay: 0.2s;
}

.Opendates span:nth-child(3) {
  animation-delay: 0.3s;
}

.Opendates span:nth-child(4) {
  animation-delay: 0.4s;
}

.Opendates span:nth-child(5) {
  animation-delay: 0.5s;
}

/* Add more delay for each additional letter */

@keyframes letter-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#overlaySay {
  z-index: 9000;
}




/* For Chrome, Safari, and newer versions of Opera */
input[type="range"]::-webkit-slider-runnable-track {
  background: transparent;
  border-radius: 0px;
}

/* For Firefox */
input[type="range"]::-moz-range-track {
  background: transparent;
}


input[type="range"]::-webkit-progress-value {
  background-color: rgb(248, 210, 49); /* Color of the filled part */
}

input[type="range"]::-moz-range-progress {
  background-color:rgb(248, 210, 49); /* Color of the filled part */
}


input[type="range"]::-ms-thumb {
  background: rgb(248, 210, 49);;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
}


